@import '@/styles/variables.scss';
























































































































































































































































































































































.label-existing {
  min-width: 180px !important;
}
.label-custom {
  position: absolute;
  top: -34px;
  left: 10px;
  width: auto;
  min-width: 75px;
  white-space: nowrap;
  &--2 {
    min-width: 120px;
  }
  .info {
    margin-left: 15px;
  }
}
