@import '@/styles/variables.scss';



















































































































































































































































































































































































































































































































.v-data-table--roles {
  .v-data-footer {
    padding: 0 !important;
  }
}
