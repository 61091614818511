@import '@/styles/variables.scss';















































































































































































































.v-stepper__header {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.v-stepper__wrapper {
  padding: 10px;
}
.v-stepper__step {
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}
.cursor-disabled {
  cursor: not-allowed;
}
// needed because stepper already has special style
.v-divider {
  &--stepper {
    border-width: thin 0 0 0 !important;
    &:after {
      display: none;
    }
  }
}
